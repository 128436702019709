import React from "react"
import Vehicle from "../../templates/Vehicle"

import style from "./vdp.module.scss"

export default function VDPAbout({ vehicle }) {
  const { VehicleInfo } = vehicle

  return (
    <section className={style["about"]}>
      <div className={style["inventory_list"]}>
        <figcaption className={style["inventory-list__figcaption"]}>
          {(VehicleInfo.hasOwnProperty("ExteriorColor")) && (
            <>
              <span className={style["inventory-list__figcaption-name"]}>
                Exterior color:
              </span>
              <span className={style["inventory-list__figcaption-value"]}>
                &nbsp;{VehicleInfo.ExteriorColor}
              </span>
            </>
          )}
        </figcaption>
        <figcaption className={style["inventory-list__figcaption"]}>
          {(VehicleInfo.hasOwnProperty("InteriorColor")) && (
            <>
              <span className={style["inventory-list__figcaption-name"]}>
                Interior color:
              </span>
              <span className={style["inventory-list__figcaption-value"]}>
                &nbsp;{VehicleInfo.InteriorColor}
              </span>
            </>
          )}
        </figcaption>
      </div>
      <p className={style["inventory-list__specs"]}>
        {`
          ${VehicleInfo.Drivetrain}, 
          ${VehicleInfo.Transmission}, 
          ${VehicleInfo.Engine},
          ${VehicleInfo.CityMPG}/${VehicleInfo.HwyMPG} 
          City/Highway MPG
        `}
      </p>
    </section>
  )
}
