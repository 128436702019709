import React, { useContext } from "react"
import { Link } from "gatsby"

import AutoCheckButton from '../autocheckButton/AutoCheckButton'
import { ModalVehicleContext } from "../modal/storage/VehicleContext"
import FinanceCalculator from "../../Modules/financeCalculator"
import InnerHTML from 'dangerously-set-html-content'
import { configurableString } from '../../Modules/configurableVehicleInfo'
import fastLinkIcon from "./img/fastlink.jpg"

import { captureEvent } from 'event-service/modules/capture'

import style from "./vdp.module.scss"
export default function VDPFinancing(props) {
  const { Pricing } = props.vehicle

  const { months, downpayment, rates } = props.financing
  const { buttons, fallbackText } = props
  const {IsNew, VIN} = props.vehicle.VehicleInfo
  const AutoCheckEnabled = props.AutoCheckEnabled
  const [topRate] = rates
  const price = Number(Pricing.ExtraPrice1 || Pricing.List)
  const axios = require('axios');

  const calc = new FinanceCalculator(price)
  const monthly = calc.calculateMonthlyPayment(
    price, calc.discount, calc.rebate, months, topRate, downpayment
  )

  const [, setFinanceModalContext] = useContext(ModalVehicleContext)
  const contextualizeItem = () => {
    setFinanceModalContext({ finalPrice: price, vehicleInfo: props.vehicle.VehicleInfo })
  }

  const { replacePrice, newPriceText } = props

  const setStyle = (button) => {
    if (button.hasOwnProperty("overrideTheme") && button.overrideTheme == true) {
      const styles = [
        button.hasOwnProperty("btncolor") && ["backgroundColor", button.btncolor],
        button.hasOwnProperty("btntxtcolor") && ["color", button.btntxtcolor]
      ].filter(el => el != false)
      return Object.fromEntries(styles)
    }
  }

  const renderHTMLBtn = (html) => (
    <div className="vdp-html-btns" data-vehicle-info={JSON.stringify({ ...props.vehicle.VehicleInfo, price: props.vehicle.Pricing.ExtraPrice1})}>
      <InnerHTML html={configurableString(html, props.vehicle.VehicleInfo)} />
    </div>
  )

  return (
    <>
      <div className={style["normal-vdpf"]}>
        <section className={style["details"]}>
          <div className={style["details__inner"]}>
            <ul className={style["offers"]}>
            {replacePrice ? 
                  <li className={style["offer"]}>
                    <p className={style["offer-name"]}>
                      { newPriceText !== undefined ? newPriceText : '' }
                    </p>
                  </li>
              :
                (Pricing.ExtraPrice1 < Pricing.List && Pricing.List > 0 && Pricing.ExtraPrice1 > 0) ? <li className={style["offer"]}>
                  <div className={style["offer-cont"]} style={{ marginBottom: "7px", opacity: "0.5" }}>
                    <p className={style["offer-name"]}><button className={style["offer-btn"]}>{IsNew ? "MSRP" : "Retail Price"}</button></p>
                    <p className={style["offer-value"]}>
                      {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                    </p>
                  </div>
                  <div className={style["offer-cont"]}>
                    <p className={style["offer-name"]}><button className={style["offer-btn"]}>Dealer Price</button></p>
                    <p className={style["offer-value"]}>
                      {Pricing.ExtraPrice1 > 0 ? `$${FinanceCalculator.formatLocale(Pricing.ExtraPrice1)}` : fallbackText}
                    </p>
                  </div>
                  <div className={style["offer-cont"]} style={{ marginTop: "7px"}}>
                    <p className={style["offer-name"]}><button className={style["offer-btn"]}>Total Savings</button></p>
                    <p className={style["offer-value"]}>
                      {Pricing.ExtraPrice1 > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List - Pricing.ExtraPrice1)}` : fallbackText}
                    </p>
                  </div>
                </li> :
                  <li className={style["offer"]}>
                    <div className={style["offer-cont"]}>
                      <p className={style["offer-name"]}>
                        <button className={style["offer-btn"]}>
                        {IsNew ? 
                          Pricing.ExtraPrice1 == Pricing.List ? "MSRP" : "Dealer Price"
                          :
                          Pricing.ExtraPrice1 == Pricing.List ? "Retail Price" : "Dealer Price"
                        }
                        </button>
                      </p>
                      <p className={style["offer-value"]}>
                        {price > 0 ? `$${FinanceCalculator.formatLocale(price)}` : fallbackText}
                      </p>
                    </div>
                  </li>
              }
              {replacePrice ? <></> :
                price > 0 && (
                  <li className={style["offer"]}>
                    <div className={style["offer-cont"]}>
                      <p className={style["offer-name"]}>
                        <button className={style["offer-btn"]}>Finance For</button>
                      </p>
                      <p className={style["offer-value"]}>
                        <button className={style["offer-btn offer-btn_underline"]}>
                          {`$${FinanceCalculator.formatLocale(monthly)}/Mo`}
                        </button>
                      </p>
                    </div>
                  </li>
                )
              }
            </ul>

            <div className={`${style.controls} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-controls' : 'used-vdp-controls'}`}>
              {buttons.map(button => (
                (button.html === "{{autocheck}}") ?
                <AutoCheckButton 
                  vin={VIN} 
                  IsNew={IsNew} 
                  AutoCheckEnabled={AutoCheckEnabled} 
                />
                :
                (button.html != undefined && button.html != '' && button.html != '<p></p>')
                ?
                renderHTMLBtn(button.html)
                :
                (button.buttonLink.includes('tel:')
                  ?
                  <a className={`${style["control-a-wrap"]} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase() : 'used-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase()}`} href={button.buttonLink}>
                    <button className={style["control"]} style={setStyle(button)}>
                      {
                        (button.buttonImage && button.buttonImage.length > 0) ? (
                          <img src={button.buttonImage} style={{ height: "100%" }} />
                        ) : button.buttonText
                      }
                    </button>
                  </a>
                  :
                  <Link className={`${style["control-a-wrap"]} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase() : 'used-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase()}`} to={configurableString(button.buttonLink, props.vehicle.VehicleInfo)}>
                    <button className={style["control"]} style={setStyle(button)}
                      onClick={event => {
                        contextualizeItem()
                        captureEvent({
                          event: 'asc_cta_interaction',
                          element_text: `${event.currentTarget.innerText}`,
                          element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                          element_type: 'button',
                          event_action: 'click',
                          event_action_result: 'open form',
                          link_url: `${button.buttonLink}`,
                          item_id: props.vehicle.VehicleInfo.VIN,
                          item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                          item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                          item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                          item_year: `${props.vehicle.VehicleInfo.Year}`,
                          item_make: `${props.vehicle.VehicleInfo.Make}`,
                          item_model: `${props.vehicle.VehicleInfo.Model}`,
                          item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                          item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                          item_type: '',
                          item_category: '',
                          item_fuel_type: '',
                          item_inventory_date: ''
                        }, {
                          handlers: ['ASC']
                        }).then(res => console.log(res)).catch(err => console.log(err))
                      }}>
                      {
                        (button.buttonImage && button.buttonImage.length > 0) ? (
                          <img src={button.buttonImage} style={{ height: "100%" }} />
                        ) : configurableString(button.buttonText, props.vehicle.VehicleInfo)
                      }
                    </button>
                  </Link>
                )
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

VDPFinancing.defaultProps = {
  financing: {
    months: 0, rate: 0, downpayment: 0
  }
}
