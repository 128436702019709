import React, { useState, useRef } from "react"
import Slider from "react-slick";
import { captureEvent } from 'event-service/modules/capture' 

import style from "./vdp.module.scss"

import iconPrev from "../img/icon-prev.svg"
import iconNext from "../img/icon-next.svg"

import unavailable from "../img/slide-01.jpg"
import VDPMainImage from "./VDPMainImage";
import useFullSlider from "../../hooks/useFullSlider";

export default function VDPSlides(props) {
  const slider = useRef(null)
  const { ListOfPhotos } = props.vehicle
  const { unavailableImg } = props
  const [slide, setSlide] = useState(0)

  const [renderSlider, openSlider] = useFullSlider(ListOfPhotos, slide, unavailableImg)

  const handleImgError = (event) => {
    event.target.onerror = null;
    event.target.src = unavailableImg
  }

  const displayImg = () => {
    if (ListOfPhotos[slide] && ListOfPhotos[slide].hasOwnProperty("PhotoUrl"))
      return ListOfPhotos[slide].PhotoUrl

    return unavailableImg
  }

  const renderSlide = () => (
    ListOfPhotos.map((slide, idx) => (
      <li className={style["slides-preview__slide"]}
        style={{ cursor: "pointer" }} >
        <img className={style["slides-preview__img"]} alt=""
          src={slide.PhotoUrl}
          onError={(e) => handleImgError(e)}
          onClick={() => {
            captureEvent({
              event: 'asc_media_interaction',
              event_action_result: 'click',
              affiliation: '',
              media_type: 'image',
              link_url: window.location.href,
              creative_name: '',
              item_id: document.querySelector('data[id="vin"]')?.value || '',
              item_number: document.querySelector('data[id="stock"]')?.value || '',
              item_price: document.querySelector('data[id="price"]')?.value || '',
              item_condition: document.querySelector('data[id="condition"]')?.value || '',
              item_year: document.querySelector('data[id="year"]')?.value || '',
              item_make: document.querySelector('data[id="make"]')?.value || '',
              item_model: document.querySelector('data[id="model"]')?.value || '',
              item_variant: document.querySelector('data[id="trim"]')?.value || '',
              item_color: document.querySelector('data[id="color"]')?.value || '',
              item_type: document.querySelector('data[id="type"]')?.value || '',
              item_category: document.querySelector('data[id="category"]')?.value || '',
              item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
              item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
              item_results: document.querySelector('data[id="results"]')?.value || '',
            }, {
              handlers: ['ASC']
            }).then(res => console.log(res)).catch(err => console.log(err))
            slider.current.slickGoTo(idx)
          }} />
      </li>
    ))
  )

  const settings = {
    arrows: false,
    infinite: true,
    autoplay: false,
    slidesToShow: Math.min(ListOfPhotos.length, 4),
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (old, idx) => {
      setSlide(idx)
    },
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: Math.min(ListOfPhotos.length, 5),
          slidesToScroll: Math.min(ListOfPhotos.length, 1),
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(ListOfPhotos.length, 4),
          slidesToScroll: Math.min(ListOfPhotos.length, 1),
        }
      }
    ]
  }

  return (
    <section className={style["slider"]}>
      <div style={{ cursor: "pointer" }} onClick={openSlider}>
        <VDPMainImage {...props} slide={slide} setSlide={setSlide} gotoSlide={(slide) => {
          slider.current.slickGoTo(slide)
        }} />
      </div>

      {
        (ListOfPhotos.length > 1) &&
        <div className={style["slides-preview"]}>
          <button className={style["slides-preview__prev-btn"]} onClick={() => {
            captureEvent({
              event: 'asc_media_interaction',
              event_action_result: 'click',
              affiliation: '',
              media_type: 'image',
              link_url: window.location.href,
              creative_name: '',
              item_id: document.querySelector('data[id="vin"]')?.value || '',
              item_number: document.querySelector('data[id="stock"]')?.value || '',
              item_price: document.querySelector('data[id="price"]')?.value || '',
              item_condition: document.querySelector('data[id="condition"]')?.value || '',
              item_year: document.querySelector('data[id="year"]')?.value || '',
              item_make: document.querySelector('data[id="make"]')?.value || '',
              item_model: document.querySelector('data[id="model"]')?.value || '',
              item_variant: document.querySelector('data[id="trim"]')?.value || '',
              item_color: document.querySelector('data[id="color"]')?.value || '',
              item_type: document.querySelector('data[id="type"]')?.value || '',
              item_category: document.querySelector('data[id="category"]')?.value || '',
              item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
              item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
              item_results: document.querySelector('data[id="results"]')?.value || '',
            }, {
              handlers: ['ASC']
            }).then(res => console.log(res)).catch(err => console.log(err))
            setSlide(slide)
            slider.current.slickPrev()
          }}>
            <img className={style["slides-preview__prev-btn-img"]} src={iconPrev} alt="Previous slide" />
          </button>

          <ul className={style["slides-preview__slides"]}>
            <Slider {...settings} ref={slider}>
              {renderSlide()}
            </Slider>
          </ul>

          <button className={style["slides-preview__next-btn"]} onClick={() => {
            captureEvent({
              event: 'asc_media_interaction',
              event_action_result: 'click',
              affiliation: '',
              media_type: 'image',
              link_url: window.location.href,
              creative_name: '',
              item_id: document.querySelector('data[id="vin"]')?.value || '',
              item_number: document.querySelector('data[id="stock"]')?.value || '',
              item_price: document.querySelector('data[id="price"]')?.value || '',
              item_condition: document.querySelector('data[id="condition"]')?.value || '',
              item_year: document.querySelector('data[id="year"]')?.value || '',
              item_make: document.querySelector('data[id="make"]')?.value || '',
              item_model: document.querySelector('data[id="model"]')?.value || '',
              item_variant: document.querySelector('data[id="trim"]')?.value || '',
              item_color: document.querySelector('data[id="color"]')?.value || '',
              item_type: document.querySelector('data[id="type"]')?.value || '',
              item_category: document.querySelector('data[id="category"]')?.value || '',
              item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
              item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
              item_results: document.querySelector('data[id="results"]')?.value || '',
            }, {
              handlers: ['ASC']
            }).then(res => console.log(res)).catch(err => console.log(err))
            setSlide(slide)
            slider.current.slickNext()
          }}>
            <img className={style["slides-preview__next-btn-img"]} src={iconNext} alt="Next slide" />
          </button>
        </div>
      }

      { renderSlider() }
    </section>
  )
}

VDPSlides.defaultProps = {
  unavailableImg: unavailable
}
